<template>
  <div>
    <div class="page-title">圆片库存管理</div>
    <!-- 选项 -->
    <div style="float:left;width:100%;margin-top:0.5rem">
      <el-tooltip content="送货单日期" effect="light" placement="top" transition="none" style="width:180px">
        <el-date-picker
          v-model="colScreen.送货单日期"
          size="mini"
          range-separator="至"
          type="daterange"
        ></el-date-picker>
      </el-tooltip>
      <el-tooltip content="入库日期" effect="light" placement="top" transition="none" style="width:180px">
        <el-date-picker
          v-model="colScreen.入库日期"
          size="mini"
          range-separator="至"
          type="daterange"
        ></el-date-picker>
      </el-tooltip>
      <el-tooltip content="出库日期" effect="light" placement="top" transition="none" style="width:180px">
        <el-date-picker
          v-model="colScreen.出库日期"
          size="mini"
          range-separator="至"
          type="daterange"
        ></el-date-picker>
      </el-tooltip>
      <el-button type="primary" size="mini" style="margin-left:3px" @click="GetRoundSku(1)">筛选</el-button>
    </div>
    <!-- 按钮 -->
    <div style="float:left;width:100%;margin-top:0.5rem">
      <el-button type="primary" plain size="mini" @click="InLayer">导入圆片</el-button>
      <el-button type="success" plain size="mini" @click="Output">导出圆片</el-button>
      <el-button type="danger" plain size="mini" @click="RwLayer">覆盖导入</el-button>
      <el-button type="danger" plain size="mini" @click="BatLayer">导入修改</el-button>
      <el-button type="warning" plain size="mini" @click="BsLayer">修改选中</el-button>
      <el-button type="danger" size="mini" @click="DelLayer">删除选中</el-button>
      <el-button type="success" plain size="mini" @click="SelectAll">全选本页</el-button>
      <el-button type="danger" plain size="mini" @click="ClearSelected">清空选中</el-button>
      <el-button type="warning" plain size="mini" @click="ClearAll">清空选项</el-button>
    </div>
    <!-- 合计显示 -->
    <div style="float:left;width:100%;margin-top:0.8rem;padding:0.2rem 0">
      根据筛选条件可得合计数量 Qty：{{ sumQty }} &nbsp; 片数：{{ count }}
    </div>
    <!-- 主表格 -->
    <div style="width:100%;float:left;margin-top:1rem">
      <pl-table
        use-virtual
        class="tb-edit"
        :data="roundData"
        border
        @row-click="RowClick"
        @row-dblclick="RowDblClick"
        @row-contextmenu="RowRightClick"
        ref="multipleTable"
        :row-class-name="GetRowClassName"
        height="600"
        :cell-style="{padding:0,height:'28px','text-align':'center'}"
        :row-height="28"
        :header-cell-style="{padding:0,height:'30px'}"
      >
        <pl-table-column label="公司" prop="公司" width="90">
          <template slot="header">
            <el-tooltip content="公司" effect="light" placement="top" transition="none" style="width:100%">
              <el-select
                v-model="colScreen.公司"
                placeholder="公司"
                size="mini"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.公司" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column label="在库状态" prop="在库状态" width="90">
          <template slot="header">
            <el-tooltip content="在库状态" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.在库状态"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="在库状态"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.在库状态" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="波段" prop="波段">
          <template slot="header">
            <el-tooltip content="波段" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.波段"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="波段"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.波段" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="型号" prop="型号">
          <template slot="header">
            <el-tooltip content="型号" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.型号"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="型号"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.型号" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="100" label="送货单日期" prop="送货单日期">
        </pl-table-column>
        <pl-table-column width="100" label="入库日期" prop="入库日期">
        </pl-table-column>
        <pl-table-column width="100" label="出库日期" prop="出库日期">
        </pl-table-column>
        <pl-table-column width="100" label="出库流向" prop="出库流向">
          <template slot="header">
            <el-tooltip content="出库流向" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.出库流向"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="出库流向"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.出库流向" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="130" label="备注" prop="备注">
          <template slot="header">
            <el-tooltip content="备注" effect="light" placement="top" style="width:100%">
              <el-input
                v-model="colScreen.备注"
                size="mini"
                placeholder="备注"
              />
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="厂家入库单号" prop="厂家入库单号">
          <template slot="header">
            <el-tooltip content="厂家入库单号" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.厂家入库单号"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="厂家入库单号"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.厂家入库单号" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="120" label="品号" prop="品号">
          <template slot="header">
            <el-tooltip content="品号" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.品号"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="品号"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.品号" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="120" label="厂家片号" prop="厂家片号">
          <template slot="header">
            <el-tooltip content="厂家片号" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.厂家片号"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="厂家片号"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.厂家片号" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="仓库位置" prop="仓库位置">
          <template slot="header">
            <el-tooltip content="仓库位置" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.仓库位置"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="仓库位置"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.仓库位置" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="120" label="入库备注" prop="入库备注">
          <template slot="header">
            <el-tooltip content="入库备注" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.入库备注"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="入库备注"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.入库备注" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="120" label="单片备注" prop="单片备注">
          <template slot="header">
            <el-tooltip content="单片备注" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.单片备注"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="单片备注"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.单片备注" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="厂家入库时间" prop="厂家入库时间">
        </pl-table-column>
        <pl-table-column width="90" label="Spec" prop="Spec">
          <template slot="header">
            <el-tooltip content="Spec" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.Spec"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="Spec"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.Spec" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="Qty" prop="Qty">
          <template slot="header">
            <el-tooltip content="Qty" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.Qty"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="Qty"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.Qty" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="VF1_MIN" prop="VF1_MIN">
          <template slot="header">
            <el-tooltip content="VF1_MIN" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.VF1_MIN"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="VF1_MIN"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.VF1_MIN" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="VF1_AVG" prop="VF1_AVG">
          <template slot="header">
            <el-tooltip content="VF1_AVG" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.VF1_AVG"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="VF1_AVG"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.VF1_AVG" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="VF1_MAX" prop="VF1_MAX">
          <template slot="header">
            <el-tooltip content="VF1_MAX" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.VF1_MAX"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="VF1_MAX"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.VF1_MAX" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="VF1_STD" prop="VF1_STD">
          <template slot="header">
            <el-tooltip content="VF1_STD" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.VF1_STD"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="VF1_STD"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.VF1_STD" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="LOP1_MIN" prop="LOP1_MIN">
          <template slot="header">
            <el-tooltip content="LOP1_MIN" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.LOP1_MIN"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="LOP1_MIN"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.LOP1_MIN" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="LOP1_AVG" prop="LOP1_AVG">
          <template slot="header">
            <el-tooltip content="LOP1_AVG" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.LOP1_AVG"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="LOP1_AVG"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.LOP1_AVG" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="LOP1_MAX" prop="LOP1_MAX">
          <template slot="header">
            <el-tooltip content="LOP1_MAX" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.LOP1_MAX"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="LOP1_MAX"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.LOP1_MAX" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="LOP1_STD" prop="LOP1_STD">
          <template slot="header">
            <el-tooltip content="LOP1_STD" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.LOP1_STD"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="LOP1_STD"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.LOP1_STD" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="WLP1_MIN" prop="WLP1_MIN">
          <template slot="header">
            <el-tooltip content="WLP1_MIN" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.WLP1_MIN"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="WLP1_MIN"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.WLP1_MIN" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="WLP1_AVG" prop="WLP1_AVG">
          <template slot="header">
            <el-tooltip content="WLP1_AVG" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.WLP1_AVG"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="WLP1_AVG"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.WLP1_AVG" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="WLP1_MAX" prop="WLP1_MAX">
          <template slot="header">
            <el-tooltip content="WLP1_MAX" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.WLP1_MAX"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="WLP1_MAX"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.WLP1_MAX" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="WLP1_STD" prop="WLP1_STD">
          <template slot="header">
            <el-tooltip content="WLP1_STD" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.WLP1_STD"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="WLP1_STD"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.WLP1_STD" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="WLD1_MIN" prop="WLD1_MIN">
          <template slot="header">
            <el-tooltip content="WLD1_MIN" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.WLD1_MIN"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="WLD1_MIN"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.WLD1_MIN" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="WLD1_AVG" prop="WLD1_AVG">
          <template slot="header">
            <el-tooltip content="WLD1_AVG" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.WLD1_AVG"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="WLD1_AVG"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.WLD1_AVG" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="WLD1_MAX" prop="WLD1_MAX">
          <template slot="header">
            <el-tooltip content="WLD1_MAX" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.WLD1_MAX"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="WLD1_MAX"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.WLD1_MAX" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="WLD1_STD" prop="WLD1_STD">
          <template slot="header">
            <el-tooltip content="WLD1_STD" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.WLD1_STD"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="WLD1_STD"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.WLD1_STD" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="IR_MIN" prop="IR_MIN">
          <template slot="header">
            <el-tooltip content="IR_MIN" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.IR_MIN"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="IR_MIN"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.IR_MIN" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="IR_AVG" prop="IR_AVG">
          <template slot="header">
            <el-tooltip content="IR_AVG" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.IR_AVG"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="IR_AVG"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.IR_AVG" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="IR_MAX" prop="IR_MAX">
          <template slot="header">
            <el-tooltip content="IR_MAX" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.IR_MAX"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="IR_MAX"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.IR_MAX" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="IR_STD" prop="IR_STD">
          <template slot="header">
            <el-tooltip content="IR_STD" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.IR_STD"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="IR_STD"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.IR_STD" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="VF3_MIN" prop="VF3_MIN">
          <template slot="header">
            <el-tooltip content="VF3_MIN" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.VF3_MIN"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="VF3_MIN"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.VF3_MIN" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="VF3_AVG" prop="VF3_AVG">
          <template slot="header">
            <el-tooltip content="VF3_AVG" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.VF3_AVG"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="VF3_AVG"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.VF3_AVG" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="VF3_MAX" prop="VF3_MAX">
          <template slot="header">
            <el-tooltip content="VF3_MAX" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.VF3_MAX"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="VF3_MAX"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.VF3_MAX" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="VF3_STD" prop="VF3_STD">
          <template slot="header">
            <el-tooltip content="VF3_STD" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.VF3_STD"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="VF3_STD"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.VF3_STD" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="VF4_MIN" prop="VF4_MIN">
          <template slot="header">
            <el-tooltip content="VF4_MIN" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.VF4_MIN"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="VF4_MIN"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.VF4_MIN" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="VF4_AVG" prop="VF4_AVG">
          <template slot="header">
            <el-tooltip content="VF4_AVG" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.VF4_AVG"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="VF4_AVG"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.VF4_AVG" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="VF4_MAX" prop="VF4_MAX">
          <template slot="header">
            <el-tooltip content="VF4_MAX" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.VF4_MAX"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="VF4_MAX"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.VF4_MAX" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="VF4_STD" prop="VF4_STD">
          <template slot="header">
            <el-tooltip content="VF4_STD" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.VF4_STD"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="VF4_STD"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.VF4_STD" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="110" label="外延激光编码" prop="外延激光编码">
          <template slot="header">
            <el-tooltip content="外延激光编码" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.外延激光编码"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="外延激光编码"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.外延激光编码" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="110" label="外延WLP" prop="外延WLP"></pl-table-column>
        <pl-table-column width="110" label="外延WLD" prop="外延WLD"></pl-table-column>
        <pl-table-column width="110" label="外延STD" prop="外延STD"></pl-table-column>
        <pl-table-column width="110" label="外延表面等级" prop="外延表面等级"></pl-table-column>
        <pl-table-column width="110" label="K值" prop="K值"></pl-table-column>
        <pl-table-column width="110" label="K值(WLD)" prop="K值(WLD)"></pl-table-column>
        <pl-table-column width="90" label="IR点测良率" prop="IR点测良率">
          <template slot="header">
            <el-tooltip content="IR点测良率" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.IR点测良率"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="IR点测良率"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.IR点测良率" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="ESD2000良率" prop="ESD2000良率">
          <template slot="header">
            <el-tooltip content="ESD2000良率" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.ESD2000良率"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="ESD2000良率"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.ESD2000良率" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="dx_VF1" prop="dx_VF1">
          <template slot="header">
            <el-tooltip content="dx_VF1" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.dx_VF1"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="dx_VF1"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.dx_VF1" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="dx_IV" prop="dx_IV">
          <template slot="header">
            <el-tooltip content="dx_IV" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.dx_IV"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="dx_IV"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.dx_IV" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column width="90" label="dx_WLD" prop="dx_WLD">
          <template slot="header">
            <el-tooltip content="dx_WLD" effect="light" placement="top" style="width:100%">
              <el-select
                v-model="colScreen.dx_WLD"
                clearable
                filterable
                allow-create
                default-first-option
                size="mini"
                placeholder="dx_WLD"
                @change="GetRoundSku(1)"
              >
                <el-option v-for="item in 候选.dx_WLD" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
      </pl-table>
      <el-pagination
        style="margin-top:1rem"
        @current-change="PageChg"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="count">
      </el-pagination>
    </div>
    <!-- 新数据导入层 -->
    <el-dialog
      title="圆片新数据导入"
      :visible.sync="inLayer"
      fullscreen
    >
      <el-upload
        ref="upload"
        class="upload-demo"
        drag
        :action="baseUrl+'/round-ins-upload'"
        :on-success="function(res){return InAfterU(res)}"
        :before-upload="function(file){return InBeforeU(file)}"
        :data="{token}"
        :headers="{token}"
        :show-file-list="false"
        v-show="step1"
        auto-upload
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">1. 只能上传 xlsx 格式的文件</div>
        <div class="el-upload__tip" slot="tip">2. 上传前请确保表格的标题行内容准确无误</div>
        <div class="el-upload__tip" slot="tip">3. 上传完成后，程序将只导入第 1 张工作表的数据</div>
      </el-upload>
      <round-table v-show="step2" :table-data="confirmData.rs"/>
      <el-pagination
        v-show="step2"
        style="margin-top:1rem"
        @current-change="InPageChg"
        :current-page="confirmData.page"
        :page-size="confirmData.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="confirmData.count">
      </el-pagination>
      <div style="padding-top:0.5rem">
        <span style="padding:0 0.5rem" v-show="step2">
          共 {{ confirmData.count }} 条记录
          (Qty {{ confirmData.sumQty }})
        </span>
        <el-button type="primary" v-show="step2" @click="InDo" plain>确认导入</el-button>
        <el-button @click="InCl" type="danger" plain>取 消</el-button>
      </div>
    </el-dialog>
    <!-- 覆盖导入层 -->
    <el-dialog
      title="圆片覆盖导入"
      :visible.sync="rwLayer"
      fullscreen
    >
      <el-upload
        ref="upload"
        class="upload-demo"
        drag
        :action="baseUrl+'/round-rw-upload'"
        :on-success="function(res){return RwAfterU(res)}"
        :before-upload="function(file){return RwBeforeU(file)}"
        :data="{token}"
        :headers="{token}"
        :show-file-list="false"
        v-show="step1"
        auto-upload
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">1. 只能上传 xlsx 格式的文件</div>
        <div class="el-upload__tip" slot="tip">2. 上传前请确保表格的标题行内容准确无误</div>
        <div class="el-upload__tip" slot="tip">3. 上传完成后，程序将只导入第 1 张工作表的数据</div>
      </el-upload>
      <round-table v-show="step2" :table-data="confirmData.rs"/>
      <div style="padding-top:0.5rem">
        <span style="padding:0 0.5rem" v-show="step2">
          共 {{ confirmData.rs ? confirmData.rs.length : 0 }} 条记录
          (Qty {{ confirmData.sumQty }})
        </span>
        <el-button type="primary" v-show="confirmData.rs && confirmData.rs===newData" @click="RwON" plain>切换为旧数据
        </el-button>
        <el-button type="primary" v-show="confirmData.rs && confirmData.rs===oldData" @click="RwON" plain>切换为新数据
        </el-button>
        <el-button type="primary" v-show="step2" @click="RwDo" plain>确认导入</el-button>
        <el-button @click="RwCl" type="danger" plain>取 消</el-button>
      </div>
    </el-dialog>
    <!-- 导入修改层 -->
    <el-dialog
      title="圆片导入修改"
      :visible.sync="batLayer"
      fullscreen
    >
      <el-upload
        ref="upload"
        class="upload-demo"
        drag
        :action="baseUrl+'/round-bat-upload'"
        :on-success="function(res){return BatAfterU(res)}"
        :before-upload="function(file){return BatBeforeU(file)}"
        :data="{token}"
        :headers="{token}"
        :show-file-list="false"
        v-show="step1"
        auto-upload
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">1. 只能上传 xlsx 格式的文件</div>
        <div class="el-upload__tip" slot="tip">2. 上传前请确保表格的标题行内容准确无误</div>
        <div class="el-upload__tip" slot="tip">3. 上传完成后，程序将只导入第 1 张工作表的数据</div>
      </el-upload>
      <round-table v-show="step2" :table-data="confirmData.rs"/>
      <el-pagination
        v-show="step2"
        style="margin-top:1rem"
        @current-change="BatPageChg"
        :current-page="confirmData.page"
        :page-size="confirmData.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="confirmData.count">
      </el-pagination>
      <round-form ref="editForm" v-show="step3" form-name="editForm" :round-form="{}" @emit-form-data="GetFormData"/>
      <div style="padding-top:0.5rem">
        <span style="padding:0 0.5rem" v-show="step2">
          共 {{ confirmData.count }} 条记录
          (Qty {{ confirmData.sumQty }})
        </span>
        <el-button v-show="step3" style="margin-left:120px" type="success" @click="SubForm('editForm')">确认修改
        </el-button>
        <el-button type="primary" v-show="step2" @click="BatNext" plain>下一步</el-button>
        <el-button @click="BatCl" type="danger" plain>取 消</el-button>
      </div>
    </el-dialog>
    <!-- 选中修改层 -->
    <el-dialog
      title="选中圆片修改"
      :visible.sync="bsLayer"
      fullscreen
    >
      <round-table v-show="step1" :table-data="confirmData.rs"/>
      <round-form ref="bsForm" v-show="step2" form-name="bsForm" :round-form="{}" @emit-form-data="GetFormData"/>
      <div style="padding-top:0.5rem">
        <span style="padding:0 0.5rem" v-show="step1">
          共 {{ confirmData.rs ? confirmData.rs.length : 0 }} 条记录
          (Qty {{ confirmData.sumQty }})
        </span>
        <el-button v-show="step2" style="margin-left:120px" type="success" @click="SubForm('bsForm')">确认修改
        </el-button>
        <el-button type="primary" v-show="step1" @click="BsNext" plain>下一步</el-button>
        <el-button @click="BsCl" type="danger" plain>取 消</el-button>
      </div>
    </el-dialog>
    <!-- 选中删除层 -->
    <el-dialog
      title="删除选中的圆片数据"
      :visible.sync="delLayer"
      fullscreen
    >
      <round-table v-show="step1" :table-data="confirmData.rs"/>
      <div style="padding-top:0.5rem">
        <span style="padding:0 0.5rem" v-show="step1">
          共 {{ confirmData.rs ? confirmData.rs.length : 0 }} 条记录
          (Qty {{ confirmData.sumQty }})
        </span>
        <el-button type="danger" v-show="step1" @click="BsDoDel">确认删除</el-button>
        <el-button @click="BsCl" type="danger" plain>取 消</el-button>
      </div>
    </el-dialog>
    <!-- 单条修改 -->
    <el-dialog
      title="单个圆片修改"
      :visible.sync="oneLayer"
      fullscreen
    >
      <round-form ref="oneForm" v-show="step1" form-name="oneForm" :round-form="form" @emit-form-data="GetFormData"/>
      <div style="padding-top:0.5rem">
        <el-button style="margin-left:120px" type="success" @click="SubForm('oneForm')">确认修改</el-button>
        <el-button @click="OneCl" type="danger" plain>取 消</el-button>
      </div>
    </el-dialog>
    <!-- 右键菜单 -->
    <div id="contextmenu" v-show="menuVisible" class="menu">
      <div class="contextmenu__item" @click="RCEditRow">单行修改</div>
      <div class="contextmenu__item" @click="ShowLogs">操作日志</div>
      <div class="contextmenu__item" @click="RightCancel">取消</div>
    </div>
    <!-- 日志 -->
    <el-dialog
      :title="whichRightData.厂家片号+'操作日志'"
      :visible.sync="roundLogs"
      :close-on-click-modal="true"
      :lock-scroll="true"
      :append-to-body="true"
      width="660px"
    >
      <!-- 时间轴 -->
      <el-timeline>
        <el-timeline-item v-for="log in showRoundLogs" :timestamp="log.操作时间" placement="top">
          <strong>{{ log.操作事项 }}</strong>
          <div>操作人：{{ log.操作人 }}</div>
          <div v-html="log.操作内容.replace(/\n/g, '<br/>')"></div>
        </el-timeline-item>
      </el-timeline>
      <!-- 底部按钮 -->
      <div style="margin-top:30px">
        <el-button style="margin-left:50px" @click="roundLogs=false" type="danger" plain>关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {baseTitle, baseUrl} from "../configs/squareConfig";
import {Loading} from 'element-ui'
import {formRequest} from "../http/formRequest";
import {
  getRoundLogs,
  getRoundCache,
  getRoundSku,
  inRound,
  roundBat, roundBatP,
  roundBsEdit,
  roundCancel,
  roundDel,
  roundGetBs, roundOneEdit,
  rwRound
} from "../http/api";
import RoundTable from "@/components/roundTable";
import moment from "moment";
import RoundForm from "@/components/RoundForm";

let loading
export default {
  name: "ProdRoundSku",
  components: {RoundForm, RoundTable},
  data() {
    return {
      roundData: [],
      sumQty: 0,
      inLayer: false,
      rwLayer: false,
      batLayer: false,
      bsLayer: false,
      delLayer: false,
      oneLayer: false,
      step1: true,
      step2: false,
      step3: false,
      step4: false,
      colScreen: {},
      confirmData: {},
      oldData: {},
      newData: {},
      selectedIds: [],
      page: 1,
      pageSize: 1000,
      count: 0,
      token: this.$cookies.get('token'),
      cancelData: {},
      form: {},
      subScreen: {},
      baseUrl,
      候选: {},
      /* 右键菜单相关 */
      menuVisible: false,
      whichRightData: {},
      roundLogs: false,
      showRoundLogs: [],
    }
  },
  methods: {
    /* 导出 */
    Output() {
      const url = baseUrl + '/round-output'
      const colScreen = JSON.stringify(this.colScreen)
      formRequest(url, {
        token: this.token,
        colScreen,
      })
    },
    /* 取消操作 */
    RoundCl() {
      this.confirmData = this.form = {}
      roundCancel({
        token: this.token,
        clData: this.cancelData,
      })
        .then(res => {
          if (res.data.code === 'OK') this.cancelData = {}
        })
        .catch(err => console.log(err))
    },
    /* 主表格事件 */
    RowClick(row) {   // 行单击
      const id = row.id
      const idx = this.selectedIds.findIndex(item => item === id)
      if (idx === -1) this.selectedIds.push(id)
      else this.selectedIds.splice(idx, 1)
      this.$refs.multipleTable.setCurrentRow(-1)
    },
    RowDblClick(row) {    // 行双击
      this.form = row
      this.oneLayer = this.step1 = true
      this.step2 = this.step3 = false
    },
    /* 右键菜单 */
    RowRightClick(row, column, event) {
      this.whichRightData = JSON.parse(JSON.stringify(row))
      this.menuVisible = false  // 先把模态框关死，目的是 第二次或者第n次右键鼠标的时候 它默认的是true
      this.menuVisible = true   // 显示模态窗口，跳出自定义菜单栏
      event.preventDefault()    //关闭浏览器右键默认事件
      const menu = document.querySelector('.menu');
      this.StyleMenu(menu)
    },
    foo() {
      // 取消鼠标监听事件 菜单栏
      this.menuVisible = false
      document.removeEventListener('click', this.foo) // 关掉监听，
    },
    StyleMenu(menu) {
      let e = event || window.event
      let X = e.clientX
      let Y = e.clientY
      if (X > 1800) {
        menu.style.left = X - 100 + 'px'
      } else {
        menu.style.left = X + 1 + 'px'
      }
      document.addEventListener('click', this.foo) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
      if (Y > 700) {
        menu.style.top = Y - 30 + 'px'
      } else {
        menu.style.top = Y - 10 + 'px'
      }
    },
    RCEditRow() {
      this.RowDblClick(this.whichRightData)
    },
    RightCancel() {
      console.log('取消')
    },
    ShowLogs() {
      getRoundLogs({
        token: this.token,
        厂家片号: this.whichRightData.厂家片号,
      })
        .then(res => {
          this.roundLogs = true
          let 日志 = []
          res.data.rs.forEach(row => {
            const 当前日志 = JSON.parse(`[${row.日志}]`)
            日志 = 日志.concat(当前日志)
          })
          this.showRoundLogs = 日志.reverse()
        })
        .catch(err => console.log(err))
    },
    /* 选项、翻页 */
    PageChg(e) {    // 主表格翻页
      this.page = e
      this.GetRoundSku(e)
    },
    ClearAll() {    //  清空筛选
      this.colScreen = {}
    },
    ClearSelected() {   // 取消选中
      this.selectedIds = []
    },
    /* 获取圆片库存 */
    GetRoundSku(page) {
      page = parseInt(page)
      if (isNaN(page)) page = this.page
      getRoundSku({
        token: this.token,
        page,
        colScreen: this.colScreen,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            const keys = [
              '公司', '在库状态', '波段', '型号',
              '厂家入库单号', '品号',
            ]
            keys.forEach(key => {
              if (!this.候选[key]) this.候选[key] = []
            })
            const rs = res.data.rs
            rs.forEach((row, index) => {
              keys.forEach(key => {
                const idx = this.候选[key].findIndex(item => item === row[key])
                if (idx === -1 && row[key]) this.候选[key].push(row[key])
              })
              row = this.FormatDate(row)
              rs[index] = row
            })
            this.roundData = rs
            this.page = res.data.page
            this.pageSize = res.data.pageSize
            this.count = res.data.count
            this.sumQty = res.data.sumQty
          }
        })
        .catch(err => console.log(err))
    },
    /* 圆片 新数据导入 */
    InLayer() {   // 显示导入层
      this.confirmData = {}
      this.inLayer = this.step1 = true
      this.step2 = this.step3 = false
    },
    InBeforeU(file) {   // 导入 上传成功前
      loading = Loading.service({fullscreen: true})
    },
    InAfterU(res) {   // 导入 上传成功后
      loading.close()
      if (res.code === 'OK') {
        res.rs = res.rs.map(row => this.FormatDate(row))
        this.step1 = false
        this.step2 = true
        this.confirmData = {
          page: res.page,
          pageSize: res.pageSize,
          count: res.count,
          rs: res.rs,
          sumQty: res.sumQty,
        }
        this.cancelData = {
          filePath: res.filePath,
          fullPath: res.fullPath,
          uploadTime: res.uploadTime,
        }
      } else {
        this.$notify({
          type: 'error',
          title: '错误',
          message: res.msg,
          duration: 0,
        })
      }
    },
    InCl() {   // 取消导入
      this.confirmData = {}
      this.inLayer = this.step2 = this.step3 = false
      this.step1 = true
      this.RoundCl()
    },
    InDo() {   // 执行导入
      inRound({
        token: this.token,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.$message({
              type: "success",
              message: '导入成功'
            })
          }
          this.confirmData = this.cancelData = {}
          this.inLayer = this.step2 = this.step3 = false
          this.step1 = true
          this.GetRoundSku()
        })
        .catch(err => console.log(err))
    },
    InPageChg(p) {
      this.confirmData.page = p
      getRoundCache({
        token: this.token,
        page: p,
      })
        .then(res => this.confirmData.rs = res.data.rs.map(row => this.FormatDate(row)))
        .catch(err => console.log(err))
    },
    /* 圆片 覆盖导入 */
    RwLayer() {   // 显示覆盖导入层
      this.confirmData = {}
      this.step2 = this.step3 = false
      this.step1 = this.rwLayer = true
    },
    RwBeforeU(file) {   // 覆盖 上传成功前
      loading = Loading.service({fullscreen: true})
    },
    RwAfterU(res) {   // 覆盖 上传成功后
      loading.close()
      if (res.code === 'OK') {
        this.step1 = false
        this.step2 = true
        this.oldData = res.rs
        this.newData = res.rw
        let sumQty = 0
        res.rw.forEach(row => sumQty += row.Qty)
        this.confirmData = {
          rs: res.rw,
          sumQty,
        }
        this.cancelData = {
          filePath: res.filePath,
          fullPath: res.fullPath,
          uploadTime: res.uploadTime,
        }
      } else {
        this.$notify({
          type: 'error',
          title: '错误',
          message: res.msg,
        })
      }
    },
    RwON() {    // 覆盖 切换新旧数据
      this.confirmData.rs === this.newData
        ? this.confirmData.rs = this.oldData
        : this.confirmData.rs = this.newData
      let sumQty = 0
      this.confirmData.rs.forEach(row => sumQty += row.Qty)
      this.confirmData.sumQty = sumQty
    },
    RwCl() {    // 覆盖 取消
      this.confirmData = {}
      this.rwLayer = this.step2 = this.step3 = false
      this.step1 = true
      this.RoundCl()
    },
    RwDo() {    // 覆盖 执行覆盖
      rwRound({
        token: this.token,
        fullPath: this.cancelData.fullPath,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.$message({
              type: "success",
              message: '覆盖成功'
            })
          }
          this.rwLayer = this.step2 = this.step3 = false
          this.step1 = true
          this.confirmData = {}
          this.cancelData = {}
          this.GetRoundSku()
        })
        .catch(err => console.log(err))
    },
    /* 圆片 批量导入修改 */
    BatLayer() {    // 显示批量修改层
      this.confirmData = {}
      this.step2 = this.step3 = false
      this.batLayer = this.step1 = true
    },
    BatBeforeU(file) {    //  批量修改 上传成功前
      loading = Loading.service({fullscreen: true})
    },
    BatAfterU(res) {    //  批量修改 上传成功后
      loading.close()
      this.InAfterU(res)
    },
    BatPageChg(e) {    //  批量修改 翻页
      this.confirmData.page = e
      roundBatP({
        token: this.token,
        page: e,
        fullPath: this.cancelData.fullPath,
      })
        .then(res => {
          let rs = res.data.rs
          rs = rs.map(row => this.FormatDate(row))
          this.confirmData.rs = rs
        })
        .catch(err => console.log(err))
    },
    BatNext() {
      this.step3 = true
      this.step1 = this.step2 = false
    },
    BatCl() {    //  批量修改 取消
      this.confirmData = {}
      this.batLayer = this.step2 = this.step3 = false
      this.step1 = true
      this.RoundCl()
    },
    BatDo() {    //  批量修改 执行
      roundBat({
        token: this.token,
        fullPath: this.cancelData.fullPath,
        form: this.form,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.$message({
              type: 'success',
              message: '批量导入修改成功'
            })
            this.batLayer = this.step2 = this.step3 = false
            this.step1 = true
            this.confirmData = this.cancelData = this.form = {}
            this.GetRoundSku()
          }
        })
        .catch(err => console.log(err))
    },
    /* 圆片 批量选中修改、删除 */
    BsLayer() {   // 选中修改 显示弹窗
      roundGetBs({
        token: this.token,
        ids: this.selectedIds,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.bsLayer = this.step1 = true
            this.step2 = this.step3 = false
            let rs = res.data.rs
            let sumQty = 0
            rs = rs.map(row => {
              row = this.FormatDate(row)
              sumQty += row.Qty
              return row
            })
            this.confirmData = {rs, sumQty}
          }
        })
        .catch(err => console.log(err))
    },
    BsNext() {    // 选中 下一步
      this.step2 = true
      this.step1 = this.step3 = false
    },
    BsDoEdit() {    // 选中 执行修改
      roundBsEdit({
        token: this.token,
        ids: this.selectedIds,
        form: this.form
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.$message({
              type: 'success',
              message: '选中修改成功'
            })
            this.bsLayer = this.step2 = this.step3 = false
            this.step1 = true
            this.confirmData = this.cancelData = this.form = {}
            this.GetRoundSku()
          }
        })
        .catch(err => console.log(err))
    },
    DelLayer() {
      roundGetBs({
        token: this.token,
        ids: this.selectedIds,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.delLayer = this.step1 = true
            this.step2 = this.step3 = false
            let rs = res.data.rs
            let sumQty = 0
            rs = rs.map(row => {
              row = this.FormatDate(row)
              sumQty += row.Qty
              return row
            })
            this.confirmData = {rs, sumQty}
          }
        })
        .catch(err => console.log(err))
    },
    BsDoDel() {   // 选中 删除
      roundDel({
        token: this.token,
        ids: this.selectedIds,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
          }
          this.delLayer = this.step2 = this.step3 = false
          this.step1 = true
          this.confirmData = {}
          this.selectedIds = []
          this.GetRoundSku()
        })
        .catch(err => console.log(err))
    },
    BsCl() {    // 选中  取消
      this.delLayer = this.bsLayer = this.step2 = this.step3 = false
      this.step1 = true
      this.confirmData = this.form = {}
    },
    /* 单行修改 */
    OneDoEdit() {
      roundOneEdit({
        token: this.token,
        form: this.form,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.$message({
              type: 'success',
              message: '修改成功',
            })
            this.oneLayer = this.step2 = this.step3 = false
            this.form = this.confirmData = {}
            this.GetRoundSku()
          }
        })
        .catch(err => console.log(err))
    },
    OneCl() {
      this.oneLayer = this.step2 = this.step3 = false
      this.step1 = true
    },
    SubForm(formName) {
      if (this.$refs[formName]) this.$refs[formName].EmitFormData()
    },
    GetFormData(payload) {
      this.form = payload
      if (payload.formName === 'editForm') this.BatDo()
      if (payload.formName === 'bsForm') this.BsDoEdit()
      if (payload.formName === 'oneForm') this.OneDoEdit()
    },
    SelectAll() {
      this.roundData.forEach(row => {
        const idx = this.selectedIds.findIndex(item => item === row.id)
        if (idx === -1) this.selectedIds.push(row.id)
      })
    },
  },
  computed: {
    GetRowClassName() {
      return ({row}) => {
        const ids = this.selectedIds
        const idx = ids.findIndex(item => item === row.id)
        if (idx !== -1) return 'warning-row'
      }
    },
    FormatDate() {
      return row => {
        if (row.厂家入库时间) row.厂家入库时间 = moment(row.厂家入库时间).format('YYYY/MM/DD HH:mm:ss')
        if (row.入库日期) row.入库日期 = moment(row.入库日期).format('YYYY/MM/DD')
        if (row.出库日期) row.出库日期 = moment(row.出库日期).format('YYYY/MM/DD')
        if (row.送货单日期) row.送货单日期 = moment(row.送货单日期).format('YYYY/MM/DD')
        return row
      }
    }
  },
  activated() {
    document.title = '圆片库存管理 - ' + baseTitle
    this.GetRoundSku()
  },
}
</script>

<style>
.el-table .warning-row {
  background: yellow !important;
  color: red !important;
  font-weight: bold;
}


.contextmenu__item {
  display: block;
  line-height: 34px;
  text-align: center;
}

.contextmenu__item:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.menu {
  position: fixed;
  background-color: #fff;
  width: 100px;
  /*height: 106px;*/
  font-size: 12px;
  color: #444040;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  white-space: nowrap;
  z-index: 1000;
}

.contextmenu__item:hover {
  cursor: pointer;
  background: #66b1ff;
  border-color: #66b1ff;
  color: #fff;
}
</style>
