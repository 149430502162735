<template>
  <div>
    <el-form :model="formData">
      <span v-show="isNaN(formData.id)" style="float:left;width:100%;margin-bottom:1rem;">
          小提示：仅填写需要修改的项目信息，不需要修改的项目留白即可。
      </span>
      <el-form-item label-width="120px" label="厂家片号">
        {{ formData.厂家片号 }}
      </el-form-item>
      <el-form-item label-width="120px" label="公司">
        <el-select
          v-model="formData.公司"
          allow-create
          filterable
          clearable
          default-first-option
        >
          <el-option v-for="item in ['格晶','芯港','晶曌']" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label-width="120px" label="在库状态">
        <el-select
          v-model="formData.在库状态"
          allow-create
          filterable
          clearable
          default-first-option
        >
          <el-option v-for="item in ['在库','出库']" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label-width="120px" label="波段">
        <el-input v-model="formData.波段"></el-input>
      </el-form-item>
      <el-form-item label-width="120px" label="型号">
        <el-input v-model="formData.型号"></el-input>
      </el-form-item>
      <el-form-item label-width="120px" label="送货单日期">
        <el-date-picker v-model="formData.送货单日期"></el-date-picker>
      </el-form-item>
      <el-form-item label-width="120px" label="入库日期">
        <el-date-picker v-model="formData.入库日期"></el-date-picker>
      </el-form-item>
      <el-form-item label-width="120px" label="出库日期">
        <el-date-picker v-model="formData.出库日期"></el-date-picker>
      </el-form-item>
      <el-form-item label-width="120px" label="出库流向">
        <el-input v-model="formData.出库流向"></el-input>
      </el-form-item>
      <el-form-item v-show="!isNaN(formData.id)" label-width="120px" label="原备注">
        {{ formData.备注 }}
      </el-form-item>
      <el-form-item label-width="120px" label="追加备注">
        <el-input type="textarea" :rows="3" v-model="formData.追加备注"></el-input>
      </el-form-item>
      <el-form-item label-width="120px" label="厂家入库单号">
        <el-input v-model="formData.厂家入库单号"></el-input>
      </el-form-item>
      <el-form-item label-width="120px" label="品号">
        <el-input v-model="formData.品号"></el-input>
      </el-form-item>
      <el-form-item label-width="120px" label="仓库位置">
        <el-input v-model="formData.仓库位置"></el-input>
      </el-form-item>
      <el-form-item label-width="120px" label="入库备注">
        <el-input type="textarea" :rows="3" v-model="formData.入库备注"></el-input>
      </el-form-item>
      <el-form-item label-width="120px" label="单片备注">
        <el-input type="textarea" :rows="3" v-model="formData.单片备注"></el-input>
      </el-form-item>
      <el-form-item label-width="120px" label="厂家入库时间">
        <el-date-picker type="datetime" v-model="formData.厂家入库时间"></el-date-picker>
      </el-form-item>
      <el-form-item label-width="120px" label="Spec">
        <el-input v-model="formData.Spec"></el-input>
      </el-form-item>
      <div v-show="!isNaN(formData.id)">
        <el-form-item label-width="120px" label="Qty">
          <el-input v-model="formData.Qty"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="VF1_MIN">
          <el-input v-model="formData.VF1_MIN"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="VF1_AVG">
          <el-input v-model="formData.VF1_AVG"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="VF1_MAX">
          <el-input v-model="formData.VF1_MAX"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="VF1_STD">
          <el-input v-model="formData.VF1_STD"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="LOP1_MIN">
          <el-input v-model="formData.LOP1_MIN"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="LOP1_AVG">
          <el-input v-model="formData.LOP1_AVG"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="LOP1_MAX">
          <el-input v-model="formData.LOP1_MAX"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="LOP1_STD">
          <el-input v-model="formData.LOP1_STD"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="WLP1_MIN">
          <el-input v-model="formData.WLP1_MIN"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="WLP1_AVG">
          <el-input v-model="formData.WLP1_AVG"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="WLP1_MAX">
          <el-input v-model="formData.WLP1_MAX"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="WLP1_STD">
          <el-input v-model="formData.WLP1_STD"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="WLD1_MIN">
          <el-input v-model="formData.WLD1_MIN"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="WLD1_AVG">
          <el-input v-model="formData.WLD1_AVG"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="WLD1_MAX">
          <el-input v-model="formData.WLD1_MAX"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="WLD1_STD">
          <el-input v-model="formData.WLD1_STD"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="IR_MIN">
          <el-input v-model="formData.IR_MIN"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="IR_AVG">
          <el-input v-model="formData.IR_AVG"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="IR_MAX">
          <el-input v-model="formData.IR_MAX"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="IR_STD">
          <el-input v-model="formData.IR_STD"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="VF3_MIN">
          <el-input v-model="formData.VF3_MIN"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="VF3_AVG">
          <el-input v-model="formData.VF3_AVG"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="VF3_MAX">
          <el-input v-model="formData.VF3_MAX"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="VF3_STD">
          <el-input v-model="formData.VF3_STD"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="VF4_MIN">
          <el-input v-model="formData.VF4_MIN"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="VF4_AVG">
          <el-input v-model="formData.VF4_AVG"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="VF4_MAX">
          <el-input v-model="formData.VF4_MAX"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="VF4_STD">
          <el-input v-model="formData.VF4_STD"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="外延激光编码">
          <el-input v-model="formData.外延激光编码"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="IR点测良率">
          <el-input v-model="formData.IR点测良率"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="ESD2000良率">
          <el-input v-model="formData.ESD2000良率"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="dx_VF1">
          <el-input v-model="formData.dx_VF1"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="dx_IV">
          <el-input v-model="formData.dx_IV"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="dx_WLD">
          <el-input v-model="formData.dx_WLD"></el-input>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "RoundForm",
  props: ['formName', 'roundForm'],
  data() {
    return {
      formData: JSON.parse(JSON.stringify(this.roundForm)),
    }
  },
  methods: {
    EmitFormData() {
      this.formData.formName = this.formName
      this.$emit('emit-form-data', this.formData)
    }
  },
  watch: {
    roundForm: function (val, oldVal) {
      this.formData = JSON.parse(JSON.stringify(val))
    }
  }
}
</script>

<style scoped>

</style>
