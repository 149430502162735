<template>
  <div>
    <pl-table
      use-virtual
      class="tb-edit"
      :data="tableData"
      border
      height="430"
      :cell-style="{padding:0,height:'28px','text-align':'center'}"
      :row-height="28"
      :header-cell-style="{padding:0,height:'30px'}"
    >
      <pl-table-column label="公司" prop="公司"></pl-table-column>
      <pl-table-column label="在库状态" prop="在库状态"></pl-table-column>
      <pl-table-column label="波段" prop="波段"></pl-table-column>
      <pl-table-column label="型号" prop="型号"></pl-table-column>
      <pl-table-column label="送货单日期" prop="送货单日期"></pl-table-column>
      <pl-table-column label="入库日期" prop="入库日期"></pl-table-column>
      <pl-table-column label="出库日期" prop="出库日期"></pl-table-column>
      <pl-table-column label="出库流向" prop="出库流向"></pl-table-column>
      <pl-table-column label="备注" prop="备注"></pl-table-column>
      <pl-table-column label="厂家入库单号" prop="厂家入库单号"></pl-table-column>
      <pl-table-column label="品号" prop="品号"></pl-table-column>
      <pl-table-column label="厂家片号" prop="厂家片号"></pl-table-column>
      <pl-table-column label="仓库位置" prop="仓库位置"></pl-table-column>
      <pl-table-column label="入库备注" prop="入库备注"></pl-table-column>
      <pl-table-column label="单片备注" prop="单片备注"></pl-table-column>
      <pl-table-column label="厂家入库时间" prop="厂家入库时间"></pl-table-column>
      <pl-table-column label="Spec" prop="Spec"></pl-table-column>
      <pl-table-column label="Qty" prop="Qty"></pl-table-column>
      <pl-table-column label="VF1_MIN" prop="VF1_MIN"></pl-table-column>
      <pl-table-column label="VF1_AVG" prop="VF1_AVG"></pl-table-column>
      <pl-table-column label="VF1_MAX" prop="VF1_MAX"></pl-table-column>
      <pl-table-column label="VF1_STD" prop="VF1_STD"></pl-table-column>
      <pl-table-column label="LOP1_MIN" prop="LOP1_MIN"></pl-table-column>
      <pl-table-column label="LOP1_AVG" prop="LOP1_AVG"></pl-table-column>
      <pl-table-column label="LOP1_MAX" prop="LOP1_MAX"></pl-table-column>
      <pl-table-column label="LOP1_STD" prop="LOP1_STD"></pl-table-column>
      <pl-table-column label="WLP1_MIN" prop="WLP1_MIN"></pl-table-column>
      <pl-table-column label="WLP1_AVG" prop="WLP1_AVG"></pl-table-column>
      <pl-table-column label="WLP1_MAX" prop="WLP1_MAX"></pl-table-column>
      <pl-table-column label="WLP1_STD" prop="WLP1_STD"></pl-table-column>
      <pl-table-column label="WLD1_MIN" prop="WLD1_MIN"></pl-table-column>
      <pl-table-column label="WLD1_AVG" prop="WLD1_AVG"></pl-table-column>
      <pl-table-column label="WLD1_MAX" prop="WLD1_MAX"></pl-table-column>
      <pl-table-column label="WLD1_STD" prop="WLD1_STD"></pl-table-column>
      <pl-table-column label="IR_MIN" prop="IR_MIN"></pl-table-column>
      <pl-table-column label="IR_AVG" prop="IR_AVG"></pl-table-column>
      <pl-table-column label="IR_MAX" prop="IR_MAX"></pl-table-column>
      <pl-table-column label="IR_STD" prop="IR_STD"></pl-table-column>
      <pl-table-column label="VF3_MIN" prop="VF3_MIN"></pl-table-column>
      <pl-table-column label="VF3_AVG" prop="VF3_AVG"></pl-table-column>
      <pl-table-column label="VF3_MAX" prop="VF3_MAX"></pl-table-column>
      <pl-table-column label="VF3_STD" prop="VF3_STD"></pl-table-column>
      <pl-table-column label="VF4_MIN" prop="VF4_MIN"></pl-table-column>
      <pl-table-column label="VF4_AVG" prop="VF4_AVG"></pl-table-column>
      <pl-table-column label="VF4_MAX" prop="VF4_MAX"></pl-table-column>
      <pl-table-column label="VF4_STD" prop="VF4_STD"></pl-table-column>
      <pl-table-column label="外延激光编码" prop="外延激光编码"></pl-table-column>
      <pl-table-column label="IR点测良率" prop="IR点测良率"></pl-table-column>
      <pl-table-column label="ESD2000良率" prop="ESD2000良率"></pl-table-column>
      <pl-table-column label="dx_VF1" prop="dx_VF1"></pl-table-column>
      <pl-table-column label="dx_IV" prop="dx_IV"></pl-table-column>
      <pl-table-column label="dx_WLD" prop="dx_WLD"></pl-table-column>
    </pl-table>
  </div>
</template>

<script>
export default {
  name: "roundTable",
  props: ['tableData'],
}
</script>

<style scoped>

</style>
